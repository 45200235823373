import axios from "axios";

async function getAnswer(question) {
  const prompts = question.toString();
  const response = await axios.post(
    "https://api.openai.com/v1/completions",
    {
      model: "text-davinci-003",
      prompt: prompts,
      max_tokens: 500,
      
    },
    {
      headers: {
        Authorization: `Bearer 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`, // replace with your token
        
        "Content-Type": "application/json",
      },
    }
  ).then((response) => {
    // console.log(response.data.choices[0].text.trim());
    return response
  }).catch((err) => {
    alert(err.message+"\n please make sure your internet connection  is in OpenAI supported country!")
    console.error(err.message)
  });
  // return console.log(response.data.choices[0].text.trim());

  return response.data.choices[0].text.trim();
 
}



export default getAnswer;
